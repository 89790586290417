@import 'global';

footer {
  background-color: $bg-color;
  width: 100%;
  padding: 0 20px 20px 20px;
  text-align: center !important;
}

:root .footer-logo {
	width: auto;
	margin-top: 20px;
  max-height: 10vh;
}

footer > p {
  font-size:small;
  text-align: center !important;
}

.footer-social-links {
  font-size: 40px !important;
}

@media only screen and (max-width:768px) {
  footer {
    margin-bottom: 80px;
	}
}

@media only screen and (max-width:1024px) {
	footer {
    margin-bottom: 80px;
	}
}

@media only screen and (max-width:1024px) and (orientation: landscape) {
	footer {
    margin-bottom: 20px;
	}
}