@import 'global';

main {
	height: 100vh;
	font-family: 'Roboto', sans-serif;
	overflow-y: scroll; // Prevent scrolling
	overflow-x: hidden;
	position: relative;
	width: 100%;
	scroll-snap-type: y mandatory;
	scroll-behavior: contain;
	touch-action: pan-y;
	background-color: $bg-color;
	background: transparent;
}

#picture {
	background-color: $bg-color;
	background: url($bg-picture-image) no-repeat center center;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	background-attachment: fixed;
	position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: -1;
	visibility: visible;
}

.hidden {
	// visibility: hidden;
	display: none;
}

#picture-window {
	height: 75vh;
	background: transparent;
}

main > section {
	overflow-y: hidden;
	overflow-x: hidden;
  position: relative;
  z-index: 1;
}

#about {
	background-color: $bg-color;
}

#soultone {
	background-color: $bg-color-alt;
}

@media only screen and (max-width:768px) {
  #picture {
		background-size: auto 100%
	}
}

@media only screen and (max-width:1024px) {
	#picture-window {
		height: 80vh;
		background: transparent;
	}
}