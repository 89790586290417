@import 'global';

.collage {
  background: url($bg-index-image) no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-attachment: fixed;
  display: grid;
  gap: 10px;
  padding: 10px;
  grid-template-columns: repeat(4, 1fr);
}

.collage-item img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 8px;
}

.collage-header-soultone {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    background: transparent !important;
    width: 20%;
  }
}

.collage-header-collision {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    background: transparent !important;
    width: 25%;
  }
}


.artist-page-button {
  margin: 20px;
  color: $link-color !important;
  background-color: $bg-color !important;
  text-transform: none !important;
}

.artist-page-button:hover {
  background-color: $link-hover-color !important;
}

.soultone-nav-bar, .collision-nav-bar {
  color: $bg-color !important;
}

@media only screen and (max-width:768px) {
  .collage {
    grid-template-columns: repeat(2, 1fr); 
	}

  .collage-header-soultone {
    img {
      background: transparent !important;
      width: 30%;
    }
  }
  .collage-header-collision {
    img {
      background: transparent !important;
      width: 45%;
    }
  }
}
