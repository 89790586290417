@import 'global';

html, body, .root {
  width: $max-width;
  min-width: $max-width;
  height: $body-height;
  background-color: $bg-color;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  font-family: $font;
  margin: 0;
  overflow: hidden;
  position: relative;
}

:root h1, :root h6, :root p {
  color: $text-color;
}

:root h1 {
  margin: 0 0 10px 27px;
  font-size: 30px;
}

:root h6 {
  padding-bottom: 40px;
}

:root p {
  text-align: left;
}

:root ul {
  list-style: none;
}

:root .row, :root .col {
	margin: 0 0 0 0;
	padding: 0 0 0 0;
}

:root .general-container {
  background-color: $bg-color;
	max-width: $max-width;
	min-height: $body-height;
	margin: 0 0 0 0;
	padding: 0 0 0 0;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	text-align: center;
}

:root a {
  text-decoration: none;
}
