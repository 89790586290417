
/************* LAYOUT *************/
$max-width: 100vw;
$body-min-width: 100vw;;
$body-height: 100vh;

/*********** BACKGROUND ***********/
$bg-color: #000;
$bg-color-alt: #fff;

/************** FONT **************/
$font: 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
$text-color: #fff;
$alt-text-color: #000;

/************** NAV ***************/
$nav-icon-color: #fff;
$nav-drum-icon-color: #C02886;
$link-color: #fff;
$link-hover-color: #999;
$link-mobile-menu-color: #C02886;

/************* INDEX **************/
$bg-index-image: '../assets/img/victoria-web-index-2.jpg';
$bg-about-image: '../assets/img/about-me-bg.jpg';
$bg-picture-image: '../assets/img/victoria-web-index.jpg';
$logo: '../assets/img/logo.jpg';
$drum-icon: '../assets/img/drum-icon.png';
