@import 'global';

:root .logo {
	width: auto;
	margin-top: 20px;
  max-height: 25vh;
}

#home {
  height: 100vh;
	background-color: $bg-color;
	background: url($bg-index-image) no-repeat center center;
  background-attachment: scroll;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	text-align: center
}

:root .nav-item, 
:root .nav-item:active, 
:root .nav-item:visited, 
:root .nav-link, 
:root .nav-link:active, 
:root .nav-link:visited
.btn-link,
.btn-link:active,
.btn-link:visited, 
:root a,
:root a:active,
:root a:visited {
  color: $nav-icon-color;
}

:root .nav-mobile-item, 
:root .nav-mobile-item:active, 
:root .nav-mobile-item:visited,
:root .dropdown-item, 
:root .dropdown-item:active, 
:root .dropdown-item:visited {
  color: $link-mobile-menu-color;
}

.navbar {
  box-shadow: none !important;
  background-color: transparent;
}

.nav-home {
  margin: -40px auto 0 auto;
}

.drum-icon {
	width: 35%;
	height: auto;
	animation: spin 10s linear infinite
}

.image-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0 2%;
}

.image-link {
  width: 21%;
  padding: 7%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
}

.image {
  font-size: xx-small;
  width: 100%;
  height: 100%;
}

.drum-icon-logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: $nav-drum-icon-color; 
  font-size: 36px;
  margin-bottom: 5px;
}

.image-label {
  font-size: 16px;
  margin-top: 5px;
  color: $alt-text-color;
  // mix-blend-mode: difference;
  text-align: center;
}

.hand-icon {
  vertical-align: top;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.container {
  margin-right: 10px;
  margin-left: 10px;
  min-width: 98%;
}

.offcanvas-fullscreen {
  background-color: $bg-color;
  opacity: .8;
  & > * {
    opacity: 1;
  }
}

@media only screen and (max-width:1024px) {
  :root .logo {
    margin-top: -40px;
    max-height:35vh;
  }
  .nav-home {
    margin: -20px 0 0 0;
  }
  .image-link {
    width: 20%;
    padding: 4%;
  }
}

@media only screen and (max-width:768px) {
  :root .logo {
    max-height: 20vh;
  }
  .nav-home {
    margin: 50px 0 0 0;
  }
  .image-link {
    width: 25%;
    padding: 1%;
    margin: 0 4% 0 4%;
  }
}
