@import 'global';

.carousel-section {
  background-color: $bg-color;
  margin: 0;
  padding: 0;
}

.carousel-section {
  background-color: $bg-color;
  margin: 0;
  padding: 0;
}

.carousel .thumb img {
  width: 100% !important;
  height: 100% !important;
}

.carousel .slide img {
  max-height: 500px;
  max-width: 100%;
  object-fit: cover;
  object-position: left 20%;
}

.carousel .thumbs-wrapper {
  margin: 20px;
  margin-bottom: 0;
  overflow: hidden;
}