@import 'global';

#about {
	background: url($bg-about-image) no-repeat right top;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
  visibility: visible;
}

:root .about-text {
  margin: 20px 25% 20px 20px;
  overflow-y: auto;
  max-height: fit-content;
}

@media only screen and (max-width:768px) {
  #about {
    background: url($bg-about-image) no-repeat left top;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  :root .about-text {
    margin: 20px;
  }
}